<div class="c-flex-wrapper">
  <div class="c-main-container" *ngIf="tableData$ | async as tableData; else loadingOrEmpty">
    <div class="flex-col" style="margin: 0 100px 25px 100px">
      <div class="upload-download-container">
        <app-asc-inline-button class="upload-button" style="margin-right: 20px" (btnClicked)="downloadDeviceHealthReport()"
          ><mat-icon inlineIcon>download</mat-icon> Download report</app-asc-inline-button
        >
        <div class="flexer" style="flex: 1"></div>
        <app-asc-text-input
          style="margin-right: 20px"
          [placeholder]="'Health report'"
          [control]="newDeviceHealthReport"
          [appAscTooltip]="'Health report json'"
        ></app-asc-text-input>
        <app-asc-inline-button (btnClicked)="addDeviceHealthReport(newDeviceHealthReport.value)" class="upload-button"
          ><mat-icon inlineIcon>upload_file</mat-icon>Add health report</app-asc-inline-button
        >
      </div>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Date </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.localDatetime | date : 'yyyy-MM-dd, HH:mm:ss' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Operating System </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.operatingSystem?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <table
          *ngIf="tableData.operatingSystem && tableData.operatingSystem.length > 0"
          mat-table
          [dataSource]="tableData.operatingSystem"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of operatingSystemColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ column === 'installed' ? (element[column] | date) : element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="operatingSystemColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div>
                    <div *ngIf="element.computerName">Computer Name: {{ element.computerName }}</div>
                    <div *ngIf="element.lastBoot">Last Boot: {{ element.lastBoot | date }}</div>
                    <div *ngIf="element.serialNumber">Serial Number: {{ element.serialNumber }}</div>
                  </div>
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="operatingSystemColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: operatingSystemColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Software </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.software?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table *ngIf="tableData.software && tableData.software.length > 0" mat-table [dataSource]="tableData.software" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of softwareColumnsColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ column === 'installed' ? (element[column] | date) : element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="softwareColumnsColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div>
                    <div *ngIf="element.path">Path: {{ element.path }}</div>
                  </div>
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="softwareColumnsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: softwareColumnsColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Memory </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.memory?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table *ngIf="tableData.memory && tableData.memory.length > 0" mat-table [dataSource]="tableData.memory" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of memoryColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="memoryColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="memoryColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: memoryColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Hard Disks </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.hardDisks?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table *ngIf="tableData.hardDisks && tableData.hardDisks.length > 0" mat-table [dataSource]="tableData.hardDisks" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of hardDisksColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="hardDisksColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="hardDisksColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: hardDisksColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Processors </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.processors?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <table *ngIf="tableData.processors && tableData.processors.length > 0" mat-table [dataSource]="tableData.processors" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of processorsColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="processorsColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div>
                    <div *ngIf="element.maxClockInMHz">Max Clock Speed: {{ element.maxClockInMHz }} Mhz</div>
                    <div *ngIf="element.coreCount">Core Count: {{ element.coreCount }}</div>
                    <div *ngIf="element.logicalProcessorCount">Logical Core Count: {{ element.logicalProcessorCount }}</div>
                  </div>
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="processorsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: processorsColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Network Adapters </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.networkAdapters?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table
          *ngIf="tableData.networkAdapters && tableData.networkAdapters.length > 0"
          mat-table
          [dataSource]="tableData.networkAdapters"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of networkAdaptersColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="networkAdaptersColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="networkAdaptersColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: networkAdaptersColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Network Ports </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.networkPorts?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table *ngIf="tableData.networkPorts && tableData.networkPorts.length > 0" mat-table [dataSource]="tableData.networkPorts" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of networkPortsColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="networkPortsColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div>
                    <div *ngIf="element.computerName">Computer Name: {{ element.computerName }}</div>
                    <div *ngIf="element.lastBoot">Last Boot: {{ element.lastBoot | date }}</div>
                    <div *ngIf="element.serialNumber">Serial Number: {{ element.serialNumber }}</div>
                  </div>
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="networkPortsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: networkPortsColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin: 10px 0 0 0">
        <mat-expansion-panel-header>
          <mat-panel-title> Hardware </mat-panel-title>
          <mat-panel-description>
            {{ tableData?.hardware?.length || '0' }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table *ngIf="tableData.hardware && tableData.hardware.length > 0" mat-table [dataSource]="tableData.hardware" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of hardwareColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column | humanize }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="hardwareColumns.length">
              <div class="element-detail-container" [@detailExpand]="!!element.expanded ? 'expanded' : 'collapsed'">
                <div class="element-detail flex-row">
                  <div>
                    <div *ngIf="element.computerName">Computer Name: {{ element.computerName }}</div>
                    <div *ngIf="element.lastBoot">Last Boot: {{ element.lastBoot | date }}</div>
                    <div *ngIf="element.serialNumber">Serial Number: {{ element.serialNumber }}</div>
                  </div>
                  <div class="flex-col">
                    <div *ngFor="let item of element.properties | keyvalue">{{ item.key }} = {{ item.value }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="hardwareColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: hardwareColumns"
            class="element-row"
            [class.expanded-element-row]="!!element.expanded"
            (click)="element.expanded = !element.expanded"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></tr>
        </table>
      </mat-expansion-panel>
    </div>
  </div>
  <app-footer *ngIf="!device"></app-footer>
</div>

<ng-template #loadingOrEmpty>
  <ng-container *ngIf="(tableData$ | async) === null; else loadingSpinner">
    <h2 style="margin: 300px; text-align: center">No data available!</h2>
  </ng-container>
</ng-template>

<ng-template #loadingSpinner>
  <mat-spinner class="c-loading-spinner"></mat-spinner>
</ng-template>
