import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, catchError, concatMap, of } from 'rxjs';
import { DeviceHealthReport } from 'src/app/device/interfaces/DeviceHealthReport';
import { ToastsService } from 'src/app/error-handling/services/toasts.service';
import { Device } from '../../interfaces/Device';
import { DeviceApiService } from '../../services/device-api.service';

@Component({
  selector: 'app-device-health',
  templateUrl: './device-health.component.html',
  styleUrls: ['./device-health.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeviceHealthComponent implements OnInit {
  @Input() device: Device | null = null;

  //formControls
  newDeviceHealthReport = new FormControl<string | null>('');

  softwareInput = new FormControl('');
  adapterInput = new FormControl('');
  networPortsInput = new FormControl('');

  constructor(private route: ActivatedRoute, private apiService: DeviceApiService, private titleService: Title, private toastService: ToastsService) {
    this.route.params.subscribe((params) => (this.deviceId = params.deviceId));
  }
  deviceId: string;
  operatingSystemColumns: string[] = ['name', 'description', 'vendor', 'version', 'installed'];
  softwareColumnsColumns: string[] = ['name', 'description', 'vendor', 'version', 'installed'];
  memoryColumns: string[] = ['name', 'description', 'vendor', 'capacityInBytes'];
  hardDisksColumns: string[] = ['name', 'description', 'vendor', 'capacityInBytes'];
  processorsColumns: string[] = ['name', 'description', 'vendor', 'serialNumber'];
  networkAdaptersColumns: string[] = ['name', 'description', 'vendor', 'macAddress', 'speedInbitsPerSec'];

  networkPortsColumns: string[] = ['pid', 'status', 'protocol', 'localAddress', 'localPort', 'foreignAddress', 'foreignPort'];

  hardwareColumns: string[] = ['name', 'description', 'vendor', 'version'];

  localDatetimeColumns: Date;

  //device: Device | null;
  deviceHealthReport: DeviceHealthReport | null;
  isLoading = false;
  isError = false;
  tableInfoText = 'No data';
  now = moment();
  tenDaysFromNow = this.now.add(10, 'days');
  tableData$: Observable<DeviceHealthReport | null>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    //  init the title
    this.titleService.setTitle('ASC - Device List');
    this.tableData$ = this.getDeviceHealthReportAsync();
  }

  downloadDeviceHealthReport(): void {
    this.getDeviceHealthReportAsync().subscribe((report) => {
      if (report) {
        const blob = new Blob([JSON.stringify(report)], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `device-health-report-${report.deviceId}.json`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }

  getDeviceHealthReport(): void {
    this.isLoading = true;
    of(this.device)
      .pipe(
        concatMap((device) => {
          if (!device) {
            return this.apiService.getDevice(this.deviceId);
          }
          return of(device);
        }),
        concatMap((device) => {
          this.device = device;
          return this.apiService.getDeviceHealthReport(device.id);
        }),
        catchError(() => {
          this.setError('Something went wrong');
          return of();
        })
      )
      .subscribe((healthReport) => {
        if (healthReport) this.setHealthReport(healthReport);
      });

    this.apiService.getDevice(this.deviceId).subscribe({
      next: (device) => {
        this.device = device;
        this.apiService.getDeviceHealthReport(this.deviceId).subscribe({
          next: (healthReport) => {
            if (healthReport) this.setHealthReport(healthReport);
          },
          error: (error) => {
            console.log('Error in device list API', error);
            this.setError('Could not retrieve device health');
          },
        });
      },
      error: (error) => {
        console.log('Error in device list API', error);
        this.setError('Sorry, something went wrong !!');
      },
    });
  }

  getDeviceHealthReportAsync(): Observable<DeviceHealthReport | null> {
    return of(this.device).pipe(
      concatMap((device) => {
        if (!device) {
          return this.apiService.getDevice(this.deviceId);
        }
        return of(device);
      }),
      concatMap((device) => {
        return this.apiService.getDeviceHealthReport(device.id);
      }),
      catchError((err) => {
        console.log('Error in device list API', err);
        this.setError('Could not retrieve device health');
        return of();
      })
    );
  }

  addDeviceHealthReport(healthReport: string | null): void {
    this.apiService
      .patchDeviceHealthReport(this.deviceId, healthReport)
      .pipe(
        catchError((err) => {
          this.toastService.raise({ title: 'Upload health report', message: 'Device health report is invalid.' }, 'ERROR');
          console.log('Error in device health API', err);
          this.setError('Could not add device health report.');
          return of();
        })
      )
      .subscribe(() => {
        this.toastService.raise({ title: 'Upload health report', message: 'Device health report uploaded successfully.' }, 'SUCCESS');
        this.tableData$ = this.getDeviceHealthReportAsync();
      });
  }

  getCertificateStatus(): string {
    if (this.device?.currentCertificate?.validUntil) {
      if (moment(this.device.currentCertificate.validUntil) < this.now) {
        return 'EXPIRED';
      } else if (moment(this.device.currentCertificate.validUntil) < this.tenDaysFromNow) {
        return 'Expires in ' + this.tenDaysFromNow.diff(this.device.currentCertificate.validUntil, 'days');
      } else {
        return 'VALID';
      }
    } else {
      return 'EXPIRED';
    }
  }

  setHealthReport(healthReport: DeviceHealthReport) {
    this.deviceHealthReport = healthReport || ({} as DeviceHealthReport);
    this.isLoading = false;
    this.isError = false;
  }
  setError(message: string) {
    this.isError = true;
    this.tableInfoText = message;
    this.isLoading = false;
  }
}
