<app-asc-dialog-wrapper type="default">
  <ng-container dialogTitle> {{ isUpdateMode ? 'Edit Device Type: ' + data.deviceType.deviceType : 'Add new Device Type'
    }} </ng-container>

  <div class="dialog-content">

    <app-asc-text-input *ngIf="!isUpdateMode" [control]="formGroup.controls.name" maxWidth="100%"
      label="Device Type Name"
      placeholder="Device Type name..."
      errorMessage="Enter a Device Type Name"></app-asc-text-input>

    <app-asc-text-input [control]="formGroup.controls.modelType" maxWidth="100%" label="Model Type"
      placeholder="Model Type..."
      errorMessage="Enter the Model Type"></app-asc-text-input>

    <div class="dialog-properties">
      <div>
        <mat-checkbox [formControl]="formGroup.controls.sMaxMatch">SMAX match</mat-checkbox>
        <mat-checkbox [formControl]="formGroup.controls.updateDhs">Update DHS</mat-checkbox>
        <mat-checkbox [formControl]="formGroup.controls.unpackArchives">Unpack Archives</mat-checkbox>
      </div>
      <div>
        <h4>Remote Session Types</h4>
        <mat-checkbox style="padding-left: 10px;" [formControl]="formGroup.controls.gateKeeper">Gate Keeper
          Connection</mat-checkbox>
        <mat-checkbox style="padding-left: 10px;" [formControl]="formGroup.controls.quickConnection">Quick
          Connection</mat-checkbox>
      </div>

    </div>
    <app-asc-text-input [control]="formGroup.controls.specialProcessingPattern" maxWidth="100%"
      label="Protected archive regex"
      placeholder="Regex for protected archive..."
      errorMessage="Enter a valid regex"></app-asc-text-input>
    <app-asc-text-input [control]="formGroup.controls.uploadApprovalRule" maxWidth="100%"
      class="approval-rules"
      type="textarea"
      rows="3"
      label="Approval rules for log file uploads"
      placeholder="Enter a valid approval rule..."
      errorMessage="Enter a valid approval rule." class="approval-rules"></app-asc-text-input>
  </div>

  <app-asc-button class="action-button" dialogActionButton (btnClicked)="actionButtonClicked()"
    [disabled]="formGroup.invalid || loading || formGroup.pristine"> {{ isUpdateMode ? 'Save': 'Add' }}</app-asc-button>
  <app-asc-button class="action-button" dialogCancelButton type="cancel"
    (btnClicked)="closeButtonClicked()">Cancel</app-asc-button>
</app-asc-dialog-wrapper>
